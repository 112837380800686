.container {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f2f0f0;
  }
  
  .header {
    padding-top: 20px;
    text-align: left;
    margin-left: 20px;
  }
  
  .content {
    text-align: left;
    margin-left: 20px;
  }
  
  .footer {
    padding-top: 20px;
    padding-bottom: 300px;
  }
  