.custom-button {
    background-color: #5eb66e; 
    border: none; 
    border-radius: 4px; 
    padding: 10px 15px; 
    margin-right: 10px; 
    cursor: pointer;
    color : "#fff"
  }
  
  .custom-button:disabled {
    background-color: #ccc; 
    color: #888; 
    cursor: not-allowed;
  }
  
  .button-icon {
    margin-right: 5px; 
  }
  
  .custom-button2 {
    background-color: #5eb66e;
    color: #fff; 
    border: none; 
    border-radius: 4px;
    padding: 10px 15px;
    margin-right: 10px; 
    cursor: pointer;
  }
  
  .custom-button2:disabled {
    background-color: #ccc; 
    color: #888; 
    cursor: not-allowed;
  }
  .status-processing {
    background-color: #d6bc4a;
    color: #fff; 
  }
  
  .status-uploading {
    background-color: #9cbcd9; 
    color: #fff; 
  }
  
  .status-success {
    background-color: #5eb66e; 
    color: #fff;
  }
  
  .status-error {
    background-color: #fc9928; 
    color: #fff; 
  }
  
  .status-uploaded {
    background-color: #f0ad4e; 
    color: #fff;
  }
  
  .status-confirmed {
    background-color: #246630; 
    color: #fff; 
  }
  
  .test{
    color : red
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .fixed-width {
    width: 245px; 
    margin-right: 10px; 
    margin-bottom: 10px; 
  
  }
  
  @media (max-width: 768px) {
    .fixed-width {
      width: 100%;
    }
  }
  
  .table-container {
    overflow-x: auto; /* Ajoute une barre de défilement horizontale en cas de dépassement de largeur */
  }
  
  .table td {
    white-space: nowrap; /* Empêche le texte de se retourner à la ligne */
    overflow: hidden; /* Cache tout texte dépassant la largeur de la cellule */
    text-overflow: ellipsis; /* Affiche des points de suspension (...) pour indiquer un texte tronqué */
  }

  